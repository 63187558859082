
  
  /* Basic styling for the dropdown */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    padding: 8px 10px;
    background-image: -webkit-linear-gradient(0deg, rgb(64 0 255) 0%, rgb(200 13 13) 100%) !important;    color: white;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 18px;
  }
  
  .dropdown-button:hover {
    background-color: #2980b9;
  }
  
  .dropdown-menu {
    margin-top: 10px;
    display: block;
    position: relative;
    top: 100%; /* Position the dropdown below the button */
    left: 0;
    width: 100%; /* Ensure the dropdown menu width matches the button */
    background-image: -webkit-linear-gradient(0deg, rgb(64 0 255) 0%, rgb(200 13 13) 100%) !important;    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 10px;
}
  
  .dropdown-menu a {
    padding: 12px 16px;
    display: block;
    text-decoration: none;
    color: white;
  }
  
  .dropdown-menu a:hover {
    background-color: black;
  }



  .creditbuybutton{  
padding: 5px 40px!important;
font-size: 22px;
background: #1f4037;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #05d817, #1f4037);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #99f2c8, #05d817); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

border-radius: 10px;
font-weight: bold;
  }

#cars{
  background-image: -webkit-linear-gradient(0deg, rgb(64 0 255) 0%, rgb(200 13 13) 100%) !important;    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
background: black;
appearance: none; /* Remove default arrow */
-webkit-appearance: none; /* Remove default arrow in WebKit */
-moz-appearance: none; /* Remove default arrow in Firefox */
}

#cars option{
  color: white;
  background: #2980b9 red;
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none; /* Remove default arrow in WebKit */
  -moz-appearance: none; /* Remove default arrow in Firefox */
  font-size: 18px;
  
}



.custom-select {
  position: relative;
  display: inline-block;
}



/* Hide the default arrow in Internet Explorer */
.custom-select select::-ms-expand {
  display: none;
}

.custom-select::after {
  content: '\25BC'; /* Unicode character for downward arrow */
  position: absolute;
  top: 61%;
  right: 20px;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the arrow doesn't block clicks */
  font-size: 16px;
  color: white;
}

